import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import { TransitionState } from '../../Transition.types';

import * as S from './Mask.styles';

type Props = {
  animate: TransitionState;
  onAnimationEnd: () => void;
  className?: string;
};

function Mask({ animate, onAnimationEnd, className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!animate) return;

    const tl = gsap
      .timeline({
        onComplete: onAnimationEnd,
      })
      .fromTo(
        containerRef.current,
        {
          translateY: animate === 'IN' ? '100%' : 0,
          opacity: 1,
        },
        {
          ease: 'customEaseOut',
          translateY: animate === 'IN' ? 0 : '-100%',
          delay: animate === 'IN' ? 0 : 0.3,
          duration: 0.8,
        },
        0
      );

    return () => {
      tl.kill();
    };
  }, [animate]);

  return <S.Overlay ref={containerRef} className={className} />;
}

export default Mask;
