import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { PostFull, PostShort } from '@/typings/model';
import { getPostByAlias, getPostList } from '@/services/requests';

const postListLoader = createResourceLoader<Array<PostShort>>([]);
const postLoader = createResourceLoader<Nullable<PostFull>>(null);

type State = {
  postShortList: ResourceType<Array<PostShort>>;
  postFullMap: Record<string, ResourceType<Nullable<PostFull>>>;
};

const initialState: State = {
  postShortList: postListLoader.getInitialResource(),
  postFullMap: {},
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    /** Post list */
    postListRequestPending(state) {
      state.postShortList = postListLoader.pending();
    },
    postListRequestFulfilled(state, action: PayloadAction<Array<PostShort>>) {
      state.postShortList = postListLoader.fulfill(action.payload);
    },
    postListRequestRejected(state) {
      state.postShortList = postListLoader.reject();
    },

    /** Post full */
    postRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.postFullMap[action.payload.key] = postLoader.pending();
    },
    postRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, PostFull>>
    ) {
      state.postFullMap[action.payload.key] = postLoader.fulfill(
        action.payload.value
      );
    },
    postRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.postFullMap[action.payload.key] = postLoader.reject();
    },
  },
});

const { actions, reducer } = blogSlice;
export const {
  postListRequestPending,
  postListRequestFulfilled,
  postListRequestRejected,
  postRequestPending,
  postRequestFulfilled,
  postRequestRejected,
} = actions;

export default reducer;

export function getBlogPostListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    const postListResource = selectBlogPostListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      postListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return postListResource.data;
    }

    dispatch(postListRequestPending());

    try {
      const response = await getPostList();
      dispatch(postListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(postListRequestRejected());
      return [];
    }
  };
}

export function getBlogPostByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<PostFull>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectBlogPostByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && post) {
        return post.data;
      }

      dispatch(postRequestPending({ key: alias }));
      const response = await getPostByAlias(alias);

      dispatch(
        postRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(postRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function selectBlogPostListResource(
  state: AppState
): ResourceType<Array<PostShort>> {
  return state.tager.blog.postShortList;
}

export function selectBlogPostList(state: AppState): Array<PostShort> {
  return selectBlogPostListResource(state).data;
}

export function selectBlogPostByAliasResource(
  state: AppState,
  alias: string
): ResourceType<Nullable<PostFull>> {
  return state.tager.blog.postFullMap[alias];
}
