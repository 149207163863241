import { Nullable } from '@tager/web-core';

interface ScrollManagerConfig {
  selector: string;
}

class ScrollManager {
  private locomotiveScroll: Nullable<LocomotiveScrollInstance>;
  private selector: Nullable<string>;

  private resizeObserver: Nullable<ResizeObserver>;

  constructor(config: ScrollManagerConfig) {
    this.locomotiveScroll = null;
    this.selector = config.selector;

    this.resizeObserver = null;
  }

  getLocomotiveScroll(): LocomotiveScrollInstance | null {
    return this.locomotiveScroll;
  }

  createScroll(): LocomotiveScrollInstance {
    if (!this.selector) {
      throw new Error('No selector was defined!');
    }

    const element = document.querySelector(this.selector);

    if (!element) {
      throw new Error(
        `Locomotive scroll initialization error. Element with ${this.selector} is not exist`
      );
    }

    /** We can't import `locomotive-scroll` as ES module because it throw error in SSR mode */
    const LocomotiveScroll: LocomotiveScrollConstructor =
      require('locomotive-scroll').default;

    this.locomotiveScroll = new LocomotiveScroll({
      el: element as HTMLElement,
      multiplier: 0.85,
      firefoxMultiplier: 30,
      smooth: true,
      lerp: 0.08,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    });

    new ResizeObserver(() => {
      this.locomotiveScroll?.update();
    }).observe(element);

    return this.locomotiveScroll;
  }

  createResizeObserver(): ResizeObserver {
    if (!this.selector) {
      throw new Error('Selector is not defined');
    }

    const element = document.querySelector(this.selector);

    if (!element) {
      throw new Error(
        `Locomotive scroll initialization error. Element with ${this.selector} is not exist`
      );
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.locomotiveScroll?.update();
    });

    this.resizeObserver.observe(element);

    return this.resizeObserver;
  }

  destroyResizeObserver(): void {
    if (!this.resizeObserver) {
      throw new Error('Resize observer was not defined!');
    }
    this.resizeObserver.disconnect();
  }

  createOrGetScroll(): LocomotiveScrollInstance {
    if (this.locomotiveScroll) {
      return this.locomotiveScroll;
    } else {
      return this.createScroll();
    }
  }

  refreshScroll(): LocomotiveScrollInstance {
    if (this.locomotiveScroll) {
      this.locomotiveScroll.destroy();
    }

    return this.createScroll();
  }
}

const scrollManager = new ScrollManager({ selector: '#layout' });

export default scrollManager;
