import { NextPageContext } from 'next';

import { cookie, dividePathnameAndSearch } from '@tager/web-core';

export function isAsiaCountry(country: string | undefined): boolean {
  if (!country) return false;

  return [
    'af',
    'as',
    'au',
    'bd',
    'bt',
    'bn',
    'kh',
    'cn',
    'cx',
    'cc',
    'ck',
    'fm',
    'fj',
    'pf',
    'gu',
    'hk',
    'in',
    'id',
    'jp',
    'ki',
    'la',
    'mo',
    'my',
    'mv',
    'mh',
    'mn',
    'mm',
    'nr',
    'np',
    'nc',
    'nz',
    'nu',
    'nf',
    'kp',
    'mp',
    'pk',
    'pw',
    'pg',
    'ph',
    'pn',
    'ws',
    'sg',
    'sb',
    'kr',
    'lk',
    'tw',
    'th',
    'tl',
    'tk',
    'to',
    'tv',
    'vu',
    'vn',
    'wf',
  ].includes(country.toLowerCase());
}

export const REGION = {
  UK: 'uk',
  EU: 'eu',
  ASIA: 'as',
} as const;

interface Region {
  [key: string]:
    | 'FOOTER_DISCLAIMER_UK'
    | 'FOOTER_DISCLAIMER_EU'
    | 'FOOTER_DISCLAIMER_ASIA';
}

export const REGION_DISCLAIMER: Region = {
  [REGION.UK]: 'FOOTER_DISCLAIMER_UK',
  [REGION.EU]: 'FOOTER_DISCLAIMER_EU',
  [REGION.ASIA]: 'FOOTER_DISCLAIMER_ASIA',
};

export const DEFAULT_REGION = REGION.UK;

export function getRegion(ctx: NextPageContext): string {
  const pageUrl = ctx.asPath ?? '';
  const [pathname] = dividePathnameAndSearch(pageUrl);

  if (pathname === '/eu') {
    return REGION.EU;
  }

  if (pathname === '/uk') {
    return REGION.UK;
  }

  if (pathname === '/asia') {
    return REGION.ASIA;
  }

  const regionCookie = cookie.get('region', ctx.req);

  if (regionCookie) return regionCookie;

  const countryIp = ctx.req?.headers?.['cf-ipcountry'] as string | undefined;

  if (isAsiaCountry(countryIp)) return REGION.ASIA;

  return DEFAULT_REGION;
}
