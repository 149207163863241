import React, { useEffect } from 'react';
import gsap from 'gsap';
import * as Sentry from '@sentry/node';
import { useRouter } from 'next/router';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Head from 'next/head';
import '@/assets/css/index.css';
import App from 'next/app';

import { AdminBar } from '@tager/web-panel';
import { useAnalytics } from '@tager/web-analytics';
import {
  ACCESS_TOKEN_COOKIE,
  api,
  cookie,
  dividePathnameAndSearch,
  isServer,
  redirect,
  REFRESH_TOKEN_COOKIE,
  useFixedVhProperty,
  useProgressBar,
} from '@tager/web-core';
import { ModalProvider } from '@tager/web-components';

import withRedux from '@/hocs/withRedux';
import withPerfLogs from '@/hocs/withPerfLogs';
import { CustomApp_AppContext, CustomApp_Component } from '@/typings/hocs';
import Transition from '@/components/Transition';
import RegionProvider from '@/components/RegionProvider';
import { getRegion } from '@/utils/region';
import scrollManager from '@/services/scroll';
import { checkAuthorizationThunk } from '@/store/reducers/auth';

gsap.registerPlugin(ScrollTrigger);

Sentry.init({
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'local',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    process.env.NEXT_PUBLIC_ENV,
  ].join('_'),
});

function log(message: string) {
  console.log(`%c ${message}`, 'color: green');
}

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  const router = useRouter();

  useEffect(() => {
    try {
      let scroll = scrollManager.createOrGetScroll();

      scroll.on('scroll', () => ScrollTrigger.update());
      ScrollTrigger.scrollerProxy('body', {
        scrollTop(value) {
          return arguments.length > 0 && value !== undefined
            ? scroll.scrollTo(value)
            : scroll.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
      });

      const killScrollTriggerInstances = () => {
        gsap.killTweensOf(window);
        ScrollTrigger.getAll().forEach((instance) => {
          return instance.kill(true);
        });
      };

      const handleScrollTriggerRefresh = () => {
        scroll?.update();
      };

      ScrollTrigger.addEventListener('refresh', handleScrollTriggerRefresh);
      ScrollTrigger.refresh();

      router.events.on('routeChangeComplete', () => {
        log('EVENT routeChangeComplete');

        killScrollTriggerInstances();

        setTimeout(() => {
          log('DESTROY PREVIOUS SCROLL');
          scroll = scrollManager.refreshScroll();
          scroll.on('scroll', () => {
            ScrollTrigger.update();
          });

          ScrollTrigger.refresh();
        }, 1500);
      });

      return () => {
        ScrollTrigger.removeEventListener(
          'refresh',
          handleScrollTriggerRefresh
        );
        killScrollTriggerInstances();
      };
    } catch (error) {
      console.error(error);
    }
  }, [router.events]);

  useProgressBar({ showSpinner: false });

  useFixedVhProperty({ shouldListenResize: true });

  useAnalytics({
    useBackend: true,
  });

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };

  useEffect(() => {
    const zi = document.createElement('script');
    zi.type = 'text/javascript';
    zi.src = 'https://consent.cookiebot.com/uc.js';
    zi.id = 'Cookiebot';
    zi.setAttribute('data-cbid', '22cbfb16-03e7-48d2-9c72-0496dda9dcf0');
    zi.setAttribute('data-blockingmode', 'auto');
    const s = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(zi, s);
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <AdminBar />
      <RegionProvider value={props.region}>
        <ModalProvider>
          <Transition>
            <Component {...modifiedPageProps} />
          </Transition>
        </ModalProvider>
      </RegionProvider>
    </>
  );
};

/**
 * Only use this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * Reference: https://nextjs.org/docs/advanced-features/custom-app
 */
CustomApp.getInitialProps = async (appContext) => {
  const region = getRegion(appContext.ctx);

  const pageUrl = appContext.ctx.asPath ?? '';
  const [pathname] = dividePathnameAndSearch(pageUrl);

  if (pathname === '/eu' || pathname === '/asia') {
    cookie.set('region', region, appContext.ctx.res);
    redirect({ currentUrl: pageUrl, location: '/', res: appContext.ctx.res });
  }

  if (isServer() && appContext.ctx.req) {
    const accessToken = cookie.get(ACCESS_TOKEN_COOKIE, appContext.ctx.req);
    const refreshToken = cookie.get(REFRESH_TOKEN_COOKIE, appContext.ctx.req);

    api.setAccessToken(accessToken);
    api.setRefreshToken(refreshToken);
    api.setConfig({ useRefreshToken: true });

    await Promise.all([
      appContext.ctx.store.dispatch(checkAuthorizationThunk()),
    ]);
  }

  const customAppContext: CustomApp_AppContext = {
    ...appContext,
    ctx: { ...appContext.ctx, region: region },
  };

  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const appProps = await App.getInitialProps(customAppContext);

  return { ...appProps, region };
};

export default withRedux(withPerfLogs(CustomApp));
