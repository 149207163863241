import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { TransitionState } from './Transition.types';
import Mask from './components/Mask';

type Props = {
  children: React.ReactNode;
};

function Transition({ children }: Props) {
  const router = useRouter();

  const lastPageUrlRef = useRef<string>();

  const [transitionState, setTransitionState] =
    useState<Nullable<TransitionState>>(null);

  function handlePageTransitionEnd() {
    if (!lastPageUrlRef.current) return;
    if (transitionState === TransitionState.IN)
      router.push(lastPageUrlRef.current).then(() => {
        setTimeout(() => setTransitionState(TransitionState.OUT));
      });
  }

  useEffect(() => {
    function handleChange(nextPageUrl: string) {
      if (lastPageUrlRef.current !== nextPageUrl) {
        lastPageUrlRef.current = nextPageUrl;
        setTransitionState(TransitionState.IN);

        router.events.emit('routeChangeError');
        throw 'routeChange aborted';
      }
    }

    router.events.on('routeChangeStart', handleChange);

    return () => {
      router.events.off('routeChangeStart', handleChange);
    };
  }, [router.events]);

  return (
    <React.Fragment>
      {children}
      {transitionState ? (
        <MaskElem
          animate={transitionState}
          onAnimationEnd={handlePageTransitionEnd}
        />
      ) : null}
    </React.Fragment>
  );
}

const MaskElem = styled(Mask)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
`;

export default Transition;
