import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import blogReducer from './tager/blog';
import vacanciesReducer from './tager/vacancies';
import authReducer from './auth';

const tagerReducer = combineReducers({
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
  blog: blogReducer,
  vacancies: vacanciesReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  auth: authReducer,
});

export default rootReducer;
