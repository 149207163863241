import { request, ResponseBody } from '@tager/web-core';

import {
  FileType,
  LeadsType,
  PostFull,
  PostShort,
  VacancyFull,
  VacancyShort,
} from '@/typings/model';
import { StringFieldType } from '@/typings/common';

interface UserPayload {
  email?: string;
  password?: string;
}

export interface BankType {
  id: number;
  name: string;
  googleFormUrl: StringFieldType;
}

export interface UserProfileType {
  name: StringFieldType;
  email: StringFieldType;
  withTemporaryPassword: boolean;
  banks: Array<BankType>;
}

export interface RestorePasswordType {
  token: string;
}

export interface SetPasswordType extends UserPayload {
  token: string;
}

export function submitFullForm(
  payload: Record<string, string | Array<string> | boolean>,
  id: number
) {
  return request.post({ path: `/forms/${id}/submit`, body: payload });
}

export function submitCvForm(
  payload: Record<string, string | Array<string> | boolean>
) {
  return request.post({ path: `/vacancies/cv`, body: payload });
}

export function getPostList(): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: '/tager/blog/posts',
  });
}

export function getPostByAlias(alias: string): Promise<ResponseBody<PostFull>> {
  return request.get({
    path: `/tager/blog/posts/view/${alias}`,
  });
}

export function getVacancyByAlias(
  alias: string
): Promise<ResponseBody<VacancyFull>> {
  return request.get({
    path: `/vacancies/${alias}`,
  });
}

export function getVacanciesList(): Promise<ResponseBody<Array<VacancyShort>>> {
  return request.get({
    path: `/vacancies`,
  });
}

/** Bank User api*/

export function bankUserAuthentication(payload: UserPayload): Promise<any> {
  return request.post({ path: '/tager/auth/user', body: payload });
}

export function getProfileBankUser(): Promise<ResponseBody<UserProfileType>> {
  return request.get({
    path: '/profile',
  });
}

export function restorePasswordBankUser(payload: UserPayload) {
  return request.post({
    path: '/tager/restore',
    body: payload,
  });
}

export function restorePasswordCheck(
  payload: RestorePasswordType
): Promise<{ success: boolean }> {
  return request.post({
    path: '/tager/restore/check',
    body: payload,
  });
}

export function setBankUserPassword(
  payload: SetPasswordType
): Promise<ResponseBody<{ email: string }>> {
  return request.post({
    path: '/tager/restore/complete',
    body: payload,
  });
}

export function setProfileBankUserPassword(payload: UserPayload) {
  return request.post({ path: '/profile/password', body: payload });
}

export function uploadCvFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);
  return request.post({ path: '/vacancies/upload-cv', body: formData });
}
