import React, { useState } from 'react';

export interface ContextValue {
  region: string | undefined;
  changeRegion: (value: string) => void;
}

export const RegionContext = React.createContext<ContextValue | null>(null);

function RegionProvider({
  value,
  children,
}: {
  value: string;
  children: React.ReactNode;
}) {
  const [region, setRegion] = useState(value);

  function changeRegion(value: string) {
    setRegion(value);
  }

  const contextValue = {
    region,
    changeRegion,
  };

  return (
    <RegionContext.Provider value={contextValue}>
      {children}
    </RegionContext.Provider>
  );
}

export default RegionProvider;
