import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  api,
  createResourceLoader,
  Nullable,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';
import { FETCH_STATUSES } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  BankType,
  bankUserAuthentication,
  getProfileBankUser,
  setBankUserPassword,
  SetPasswordType,
} from '@/services/requests';
import { UserProfileType } from '@/services/requests';

export type AuthStatus = 'AUTHORIZED' | 'UNAUTHORIZED';

const userProfileLoader = createResourceLoader<Nullable<UserProfileType>>(null);

interface AuthState {
  userProfile: ResourceType<Nullable<UserProfileType>>;
  status: AuthStatus;
}

const initialState: AuthState = {
  userProfile: userProfileLoader.getInitialResource(),
  status: 'UNAUTHORIZED',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userAuthorized(state, action: PayloadAction<Nullable<UserProfileType>>) {
      state.status = 'AUTHORIZED';
      if (action.payload) {
        state.userProfile = userProfileLoader.fulfill(action.payload);
      }
    },
    userUnauthorized(state) {
      state.status = 'UNAUTHORIZED';
      state.userProfile = userProfileLoader.getInitialResource();
    },
    userProfileRequestPending(state) {
      state.userProfile = userProfileLoader.pending(state.userProfile.data);
    },
    userProfileRequestFulfilled(state, action: PayloadAction<UserProfileType>) {
      state.userProfile = userProfileLoader.fulfill(action.payload);
    },
    userProfileRequestRejected(state) {
      state.userProfile = userProfileLoader.reject();
    },

    updateUserProfileLoading: (state) => {
      state.userProfile.status = FETCH_STATUSES.LOADING;
      state.userProfile.error = null;
    },
    updateUserProfileSuccess: (
      state,
      action: PayloadAction<UserProfileType>
    ) => {
      state.userProfile.status = FETCH_STATUSES.SUCCESS;
      state.userProfile.data = action.payload;
    },
    updateUserProfileError: (state, action: PayloadAction<string>) => {
      state.userProfile.status = FETCH_STATUSES.FAILURE;
      //state.userProfile.error = String(action.payload);
    },
    updateUserEmail: (state, action: PayloadAction<string>) => {
      if (state.userProfile?.data?.email) {
        state.userProfile.data.email = action.payload;
      }
    },
  },
});

export default authSlice.reducer;

export const {
  userAuthorized,
  userProfileRequestFulfilled,
  userProfileRequestPending,
  userProfileRequestRejected,
  userUnauthorized,
  updateUserProfileLoading,
  updateUserProfileSuccess,
  updateUserProfileError,
  updateUserEmail,
} = authSlice.actions;

/** Thunks **/

export const signInThunk =
  (
    ...args: Parameters<typeof bankUserAuthentication>
  ): AppThunk<Promise<UserProfileType>> =>
  async (dispatch, getState) => {
    try {
      const oauthTokensResponse = await bankUserAuthentication(...args);
      api.setAccessToken(oauthTokensResponse.data.accessToken);
      api.setRefreshToken(oauthTokensResponse.data.refreshToken);
      const response = await getProfileBankUser();
      dispatch(userAuthorized(response.data));
      return response.data;
    } catch (error) {
      api.setAccessToken(null);
      api.setRefreshToken(null);
      return Promise.reject(error);
    }
  };

export const signOutThunk = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(userUnauthorized());
  api.setAccessToken(null);
  api.setRefreshToken(null);
  return Promise.resolve();
};

export const getUserProfileThunk =
  (options?: {
    shouldInvalidate?: boolean;
  }): AppThunk<Promise<Nullable<UserProfileType>>> =>
  async (dispatch, getState) => {
    const userProfileResource = selectUserProfileResource(getState());
    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      userProfileResource,
      options?.shouldInvalidate
    );
    if (shouldGetDataFromCache) {
      return userProfileResource.data;
    }
    dispatch(userProfileRequestPending());
    try {
      const response = await getProfileBankUser();
      dispatch(userProfileRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(userProfileRequestRejected());
      return null;
    }
  };

export const setNewPasswordThunk =
  (payload: SetPasswordType): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await setBankUserPassword(payload);
      const response = await getProfileBankUser();
      dispatch(userProfileRequestFulfilled(response.data));
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const checkAuthorizationThunk =
  (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const isUserAuthorized = selectIsUserAuthorized(getState());
    if (isUserAuthorized) {
      return;
    }
    try {
      const response = await getProfileBankUser();
      dispatch(userAuthorized(response.data));
    } catch (error) {
      dispatch(userUnauthorized());
    }
  };

/** Selectors **/

export function selectIsUserAuthorized(state: AppState): boolean {
  return state.auth.status === 'AUTHORIZED';
}

export function getIsUserLoading(state: AppState): boolean {
  return state.auth.userProfile.status === 'LOADING';
}

export function selectAuthStatus(state: AppState): AuthStatus {
  return state.auth.status;
}

export function selectUserProfileResource(
  state: AppState
): ResourceType<Nullable<UserProfileType>> {
  return state.auth.userProfile;
}

export function selectUserProfile(state: AppState): Nullable<UserProfileType> {
  return selectUserProfileResource(state).data;
}

export function selectBankByID(
  state: AppState,
  id: Nullable<string | number>
): BankType | undefined {
  return selectUserProfile(state)?.banks.find((bank) => bank.id === Number(id));
}
